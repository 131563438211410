
#navigation[mode='web'] {

  height: 124px;
  width: 100%;
  .navigation{
    height: 124px;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 101px;
    position: fixed;
    background: #F9EFE4;
    top: 0;
    z-index: 999;
    box-shadow: 0px 5.33px 10.67px 0px #00000040;
  }

  .navigationZ{
    height: 100%;
    width: 100%;
    background: #F9EFE4;
  }

  .web3But{
    width: 218.67px;
    height: 61.33px;
    border-radius: 70px;
    border: 1.33px solid #000000;
    color:#000000;
    font-size: 19px;
    font-weight: 500;
  }
  .web3ButOk{
    width: 218.67px;
    height: 61.33px;
    border-radius: 70px;
    border: 1.33px solid #000000;
    color:#000000;
    font-size: 19px;
    font-weight: 500;
    background-color: #70d1fd;
  }
  .dropdown .ant-dropdown-menu{
    border: 1.33px solid #000000;
      background-color: #f9efe4 !important;
  }

  .dropdown .ant-dropdown-menu-item{
    text-align: right;
    border-bottom: 1.33px solid #000000;
    color: #2F2F30;font-size: 18px;
  }
}



#navigation[mode='mobile'] {
   
   .navigation{
      height: 44px;
      width: 100%;
      background: #F9EFE4;
      box-shadow: 0px 3.49px 6.98px 0px #00000040;
      position: fixed;
      top: 0;
   }
   .navigationZ{
    height: 44px;
    width: 100%;
 }

 .mentList{
  width: 172px;
  border-radius: 6px;
  border: 1.26px solid #000000;
  background: #F9EFE4;
  box-shadow: 0px 5.03px 5.03px 0px #00000040;
  position: fixed;
  right:4px;
  top: 44px;
  color: #000000;
 }
 .mentListItem{
    padding: 10px 0;
    border-bottom: 1.26px solid #00000080;
    text-align: right;
    font-weight: 700;
 }
} 
