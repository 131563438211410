
#FirstPage[mode=web] {
  /* font-family: 'MPlusH12B'; */
  font-family:Arial, Helvetica, sans-serif;
  height: 100vh;
}
#FirstPage[mode='mobile'] {
  font-family: 'Regular';
  font-style: normal;
  width: 100vw;
  height: 100vh;
  color: #fff;
}