#HomePage[mode='web'] {
  background-color: #fffdfa;
  color: #2f2f30;

  .homeBg {
    width: 100%;
    height: 1940px;
    background-image: url('../../../assets/web/Home/homeBg.png');
    background-size: 100% 100%;
  }
  .but {
    width: 290px;
    height: 61px;
    background-image: url('../../../assets/web/Home/but.png');
    background-size: 100% 100%;
  }

  .but:active {
    width: 290px;
    height: 61px;
    background-image: url('../../../assets/web/Home/but_active.png');
    background-size: 100% 100%;
  }

  .bgList {
    display: flex;
    align-items: center;
    position: relative;
  }
  .bgItem1 {
    position: absolute;
    left: 0;
    z-index: 55;
    width: 300px;
    height: 610px;
    transition: all 0.6s;
  }
  .bgItem1:hover {
    width: 320px;
    height: 650px;
    z-index: 99;
    transform: scale(1);
  }
  .bgItem2 {
    position: absolute;
    left: 230px;
    z-index: 44;
    width: 300px;
    height: 610px;
    transition: all 0.6s;
  }
  .bgItem2:hover {
    width: 320px;
    height: 650px;
    z-index: 99;
    transform: scale(1);
  }
  .bgItem3 {
    position: absolute;
    left: 450px;
    z-index: 33;
    width: 300px;
    height: 610px;
    transition: all 0.6s;
  }
  .bgItem3:hover {
    width: 320px;
    height: 650px;
    z-index: 99;
    transform: scale(1);
  }
  .bgItem4 {
    position: absolute;
    left: 660px;
    z-index: 22;
    width: 300px;
    height: 610px;
    transition: all 0.6s;
  }
  .bgItem4:hover {
    width: 320px;
    height: 650px;
    z-index: 99;
    transform: scale(1);
  }
  .bgItem5 {
    position: absolute;
    left: 880px;
    z-index: 11;
    width: 300px;
    height: 610px;
    transition: all 0.6s;
  }
  .bgItem5:hover {
    width: 320px;
    height: 650px;
    z-index: 99;
    transform: scale(1);
  }


  .marquee {
    --gap: 1px;
    position: relative;
    display: flex;
    overflow: hidden;
    gap: var(--gap);
    width: 100%;
  }

  .marquee__content {
    gap: var(--gap);
    min-width: 100%;
    overflow: hidden;
  }

  ul {
    padding-left: 0;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
  .enable-animation .marquee__content {
    animation: scroll 15s linear infinite;
    animation-direction: reverse;
  }


  .AuroraBox{
    margin-top: 150px;
    padding-right: 300px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .Aurora {
    border-bottom: 1.33px solid #2f2f30;
    border-top: 1.33px solid #2f2f30;
    font-size: 43px;
    font-weight: 500;
  }


  .aiBg{
    box-shadow: 0px 2.67px 5.33px 1.33px #2F2F3014;
    background-color: #fff;

  }

  .footBg{
    background-image: url('../../../assets/web/Home/Vector.png');
    background-repeat: no-repeat;
   background-position: center bottom; 
   background-size: 100% 724px;
  }

  .msg{
      border-radius: 6px;
      background: #D9D9D9;
      opacity: 0.8;
      padding: 10px;
  }
}
#HomePage[mode='mobile'] {
  color: #2f2f30;
  .HomePageT{
    width:100%;
    background-image: url("../../../assets/mobile/Home/homebg.png");
    background-size: 100% 100%;
  }
  .aiBg{
    box-shadow: 0px 1px 4px 0px #00000040;
  }

  .footBg{
    background-image: url('../../../assets/mobile/Home/Vector.png');
    background-repeat: no-repeat;
   background-position: center bottom; 
   background-size: 100% 142px;
  }

  .root {
    :global {
      .adm-swiper-slide {
        transform: scale(0.6);
        transition: all 0.3s;
      }
  
      .adm-swiper-slide-active {
        transform: scale(1);
      }
    }
  }
}